// Add API Handlers here

import { rest } from 'msw'
import { CreateCustomerRequest, CreateCustomerResponse, CustomerStatus, GetCustomersResponse } from 'twirp/api'

export const handlers = [
  rest.post('/twirp/api.v1.TendoCloudConfigurator/CreateCustomer', (req, res, ctx) => {
    console.log(req.body)
    const { customer, firstUser } = req.body as CreateCustomerRequest

    customer!.id = 'fabab8bf-3ab7-4978-bb8b-029a74c752db'

    const resp: CreateCustomerResponse = {
      customer,
      firstUser,
      tenants: [
        {
          id: '1',
          name: customer!.name + ' - Development',
          subdomain: customer!.shortName + '-dev',
          auth0OrgId: 'org_1',
          serviceErrorMessages: {},
          serviceStatuses: {},
          archived: false,
          createdAt: new Date(),
          createdFromTemplateId: 'template_1',
          customerId: 'deadbeef',
          production: false,
          dbCluster: 'default',
          includeObjectDataFromTemplate: false,
          objectDataDeployments: [],
          templateFragmentDeployments: [],
        },
        {
          id: '2',
          name: customer!.name + ' - Test',
          subdomain: customer!.shortName + '-test',
          auth0OrgId: 'org_2',
          serviceErrorMessages: {},
          serviceStatuses: {},
          archived: false,
          createdAt: new Date(),
          createdFromTemplateId: 'template_2',
          customerId: 'deadbeef',
          production: false,
          dbCluster: 'default',
          includeObjectDataFromTemplate: false,
          objectDataDeployments: [],
          templateFragmentDeployments: [],
        },
        {
          id: '3',
          name: customer!.name + ' - Production',
          subdomain: customer!.shortName,
          auth0OrgId: 'org_3',
          serviceErrorMessages: {},
          serviceStatuses: {},
          archived: false,
          createdAt: new Date(),
          createdFromTemplateId: 'template_3',
          customerId: 'deadbeef',
          production: true,
          dbCluster: 'default',
          includeObjectDataFromTemplate: false,
          objectDataDeployments: [],
          templateFragmentDeployments: [],
        },
      ],
    }

    return res(ctx.json(resp))
  }),

  rest.post('/twirp/api.v1.TendoCloudConfigurator/GetCustomers', (_req, res, ctx) => {
    const resp: GetCustomersResponse = {
      customers: [
        {
          id: '178a6021-b7bf-47d3-aab7-75a81f378523',
          name: 'Tendo Health Services',
          shortName: 'ths',
          accountNumber: '12345-67890',
          status: CustomerStatus.CUSTOMER_STATUS_INTERNAL,
        },
        {
          id: 'eb8d8d89-8126-404a-b352-5fde7566aac4',
          name: 'Always Sunny Health Network',
          shortName: 'ashn',
          accountNumber: '09876-54321',
          status: CustomerStatus.CUSTOMER_STATUS_INTERNAL,
        },
        {
          id: 'd778a526-67bc-4493-958b-4bc75650a842',
          name: 'Sacred Heart Hospital',
          shortName: 'sacred-heart',
          accountNumber: '11111-22222',
          status: CustomerStatus.CUSTOMER_STATUS_INTERNAL,
        },
      ],
    }

    return res(ctx.json(resp))
  }),
]
