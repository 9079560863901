import { useNavigate } from 'react-router-dom'
import { useModalData } from './ModalContext'
import { Customer, customerStatusFromJSON, customerStatusToJSON } from 'twirp/api'
import { Box, Button, Modal, RadioGroup, SpaceBetween } from '@cloudscape-design/components'
import { TCCService } from 'services/api'
import { useState } from 'react'

interface UpdateCustomerStatusModalProps {
  customer: Customer
}

export const UpdateCustomerStatusModalName = 'UpdateCustomerStatusModal'

export const UpdateCustomerStatusModal = (props: UpdateCustomerStatusModalProps) => {
  const navigate = useNavigate()
  const { visible, disableModal } = useModalData(UpdateCustomerStatusModalName)
  const [status, setStatus] = useState<string>(
    customerStatusToJSON(props.customer.status) || 'CUSTOMER_STATUS_INTERNAL'
  )
  const [loading, setLoading] = useState<boolean>(false)
  const handleUpdateCustomerStatus = async () => {
    setLoading(true)
    await TCCService.UpdateCustomerStatus({
      customerId: props.customer.id,
      status: customerStatusFromJSON(status),
    })
    setLoading(false)
    // refresh page
    navigate(0)
  }

  return (
    <Modal
      header="Update Customer Status"
      onDismiss={disableModal}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={disableModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateCustomerStatus} loading={loading}>
              Update
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Box padding="s">
        <Box>
          <RadioGroup
            onChange={({ detail }) => {
              setStatus(detail.value)
            }}
            value={status}
            items={[
              { value: 'CUSTOMER_STATUS_INTERNAL', label: 'Internal' },
              { value: 'CUSTOMER_STATUS_CUSTOMER', label: 'Customer' },
            ]}
          />
        </Box>
      </Box>
    </Modal>
  )
}
