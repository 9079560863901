import { useParams } from 'react-router-dom'
import {
  TextContent,
  BreadcrumbGroup,
  Spinner,
  Box,
  Table,
  Header,
  SpaceBetween,
  StatusIndicator,
  Link,
  ExpandableSection,
  ButtonDropdown,
  ButtonDropdownProps,
  Badge,
} from '@cloudscape-design/components'
import { Customer, CustomerStatus, Tenant } from 'twirp/api'
import { useState } from 'react'
import { TCCService } from 'services/api'
import { useQuery } from 'react-query'
import { useModalEnabler } from 'components/ModalContext'
import { UpdateCustomerStatusModal, UpdateCustomerStatusModalName } from 'components/UpdateCustomerStatusModal'

export const CustomerPage = () => {
  const { customerId } = useParams()

  const [tenants, setTenants] = useState<Tenant[]>([])
  const [archivedTenants, setArchivedTenants] = useState<Tenant[]>([])
  const [customer, setCustomer] = useState<Customer | undefined>()

  const { isLoading } = useQuery('getCustomer', async () => {
    let sortedTenants: Tenant[]
    let liveTenants: Tenant[]
    let archivedTenants: Tenant[]
    let customer: Customer | undefined
    if (!customerId) {
      sortedTenants = []
      liveTenants = []
      archivedTenants = []
      customer = undefined
    } else {
      const data = await TCCService.GetCustomer({
        customerId: customerId!,
      })
      sortedTenants = data.tenants.sort((a: Tenant, b: Tenant): number => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })
      customer = data.customer
      archivedTenants = sortedTenants.filter((t) => t.archived)
      liveTenants = sortedTenants.filter((t) => !t.archived)
    }
    setTenants([...liveTenants])
    setArchivedTenants([...archivedTenants])
    setCustomer(customer)
    return sortedTenants
  })

  const { enableModal } = useModalEnabler()
  const handleActionItemClick = (item: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    switch (item.detail.id) {
      case 'update-status': {
        enableModal(UpdateCustomerStatusModalName)
        break
      }
      default:
        break
    }
  }

  if (isLoading) {
    return <Spinner></Spinner>
  }

  return (
    <Box padding="m">
      <BreadcrumbGroup
        items={[
          { text: 'Customers', href: '/customers' },
          { text: customer?.name || '', href: '' },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <SpaceBetween size="m">
        <Header
          variant="h1"
          actions={
            <ButtonDropdown
              onItemClick={handleActionItemClick}
              items={[
                {
                  text: 'Update Status',
                  id: 'update-status',
                  iconName: 'status-info',
                },
              ]}
            >
              Actions
            </ButtonDropdown>
          }
        >
          <SpaceBetween direction="horizontal" size="xs">
            <TextContent>
              <h1>{customer?.name}</h1>
            </TextContent>
            <Badge color={customer?.status === CustomerStatus.CUSTOMER_STATUS_CUSTOMER ? 'green' : 'grey'}>
              {customer?.status === CustomerStatus.CUSTOMER_STATUS_CUSTOMER ? 'Customer' : 'Internal'}
            </Badge>
          </SpaceBetween>
        </Header>
        <Table
          columnDefinitions={[
            {
              id: 'tenantName',
              header: 'Name',
              cell: (t) => (
                <Link variant="secondary" href={`/customers/${customer?.id}/tenants/${t.id}`}>
                  {t.name}
                </Link>
              ),
              sortingField: 'name',
            },
            {
              id: 'subdomain',
              header: 'Subdomain',
              cell: (t) => t.subdomain,
              sortingField: 'subdomain',
            },
            {
              id: 'auth0OrgId',
              header: 'Auth0 Org ID',
              cell: (t) => t.auth0OrgId,
              sortingField: 'auth0OrgId',
            },
            {
              id: 'status',
              header: 'Status',
              cell: (_t) => (
                <Box textAlign="center">
                  <StatusIndicator type="success" />
                </Box>
              ),
              sortingField: 'subdomain',
            },
          ]}
          items={tenants}
          loading={isLoading}
          loadingText="Loading tenants"
          trackBy="id"
          visibleColumns={['tenantName', 'subdomain', 'auth0OrgId', 'status']}
          empty={
            <TextContent>
              <p>No tenants found for customer</p>
            </TextContent>
          }
          header={<Header>Tenants</Header>}
        />
        <ExpandableSection defaultExpanded={archivedTenants.length > 0} header="Archived Tenants" variant="footer">
          <Table
            columnDefinitions={[
              {
                id: 'tenantName',
                header: 'Name',
                cell: (t) => (
                  <Link variant="secondary" href={`/customers/${customer?.id}/tenants/${t.id}`}>
                    {t.name}
                  </Link>
                ),
                sortingField: 'name',
              },
              {
                id: 'subdomain',
                header: 'Subdomain',
                cell: (t) => t.subdomain,
                sortingField: 'subdomain',
              },
              {
                id: 'auth0OrgId',
                header: 'Auth0 Org ID',
                cell: (t) => t.auth0OrgId,
                sortingField: 'auth0OrgId',
              },
            ]}
            items={archivedTenants}
            loading={isLoading}
            loadingText="Loading archived tenants"
            trackBy="id"
            visibleColumns={['tenantName', 'subdomain', 'auth0OrgId']}
            empty={
              <TextContent>
                <p>No archived tenants found for customer</p>
              </TextContent>
            }
          />
        </ExpandableSection>
        <UpdateCustomerStatusModal customer={customer!} />
      </SpaceBetween>
    </Box>
  )
}
